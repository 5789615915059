import React from "react";

const Container = ({ children }) => {
  return (
    <div className="w-full h-full flex flex-row md:px-24 pt-12 justify-center pb-tenth lg:pb-16">
      <div className="w-full relative flex flex-col justify-center lg:mx-auto max-w-5xl lg:space-x-4 space-y-4">{children}</div>
    </div>
  );
};

export default Container;
