import React from "react";
import Button from "../elements/button";
import metamask from "../../assets/vector/metamask.svg";
import isologo from "../../assets/vector/isologo.svg";

const Verify = ({ status, connect, account, sign, alreadyHas }) => {
  console.log(alreadyHas);
  console.log(account?.substring(1, 10) || "");
  const purchases = {
    bronze: alreadyHas % 2 === 1,
    silver: (alreadyHas >> 1) % 2 === 1,
    gold: (alreadyHas >> 2) % 2 === 1,
  };

  const passes = []
  if (purchases.bronze) passes.push('Bronze')
  if (purchases.silver) passes.push('Silver')
  if (purchases.gold) passes.push('Gold')

  return (
    <div className="w-full lg:w-1/2 flex flex-col items-center mx-auto my-auto h-full lg:pt-32 md:px-8 lg:px-6">
      <div className="w-full md:w-3/4 px-20 md:px-0 lg:w-full flex flex-center content-center">
        <img
          className="lg:transform lg:scale-110 w-auto h-auto"
          alt="Darling Waifu NFT Game"
          src={isologo}
        />
      </div>
      <div className="w-full mt-6 mb-8 text-center font-bold text-xl bright-text">
        Play to Business NFT
      </div>
      {status === "connected" && (
        <div className="w-full text-center mb-2 px-16">
          Click below if you have bought a pass.
        </div>
      )}
      <div className="w-full flex flex-col space-y-4 content-center items-center justify-center">
        <Button
          onClick={() =>
            status === "notConnected"
              ? connect()
              : (purchases.bronze || purchases.silver || purchases.gold) &&
                sign()
          }
          link={
            typeof window !== "undefined"
              ? window.ethereum
                ? null
                : "https://metamask.io"
              : null
          }
          title="Connect to Metamask"
        >
          <div className="flex flex-row">
            {typeof window !== "undefined" && window.ethereum ? (
              status === "connected" ? (
                `Verify ${account.substr(0, 7)}...`
              ) : (
                <img
                  src={metamask}
                  alt="Metamask"
                  className="h-6 object-contain"
                />
              )
            ) : (
              "Install Metamask"
            )}
          </div>
        </Button>
        <div className="text-center w-full text-lg">
          {purchases.bronze || purchases.silver || purchases.gold
            ? `You have the following passes: ${passes.join(', ')}.`
            : "You didn't buy any passes, sorry."}
        </div>
      </div>
    </div>
  );
};

export default Verify;
