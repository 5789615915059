import Verify from "./verify";
import React, { useEffect, useState } from "react";
import Background from "../elements/background";
import Container from "./container";
import ContractLinks from "./contractLinks";
import Footer from "./footer";
import Social from "./social";
import favicon from "../../assets/vector/isologo.svg";
import { Helmet } from "react-helmet";
import preview from "../../assets/img/twitterpreview.png";
import { useMetaMask } from "metamask-react";
import { useQueryParam, StringParam } from "use-query-params";
import Signature from "../elements/signature";

const DiscordContained = ({ background }) => {
  const wallets = {
    x197b53e2: 7,
    x6aba2c5e: 1,
    x6303699e: 1,
    xeb758f2c: 1,
    x1bf582ea: 1,
    x25b4a6c2: 1,
    x0d444cde: 1,
    xacf47739: 1,
    xb19f92ec: 1,
    x2275ed75: 1,
    x2b708912: 5,
    xb8de6127: 1,
    xd330608b: 1,
    x34bc0f1f: 1,
    x93ae1e57: 1,
    xcd6d40c8: 1,
    xe7ed9b2d: 1,
    x27df3ac1: 1,
    x03056800: 1,
    xae88e1f3: 1,
    x1a8b5e1a: 1,
    x28531c24: 1,
    x18167706: 1,
    xbb5bc694: 1,
    x6ef05177: 1,
    x83c9f7ea: 1,
    x559cb5f8: 1,
    xf6701b39: 1,
    xed281639: 1,
    xea401dc1: 1,
    xbe22a502: 1,
    x6fa31108: 1,
    x07cea3fd: 7,
    xb1609cc0: 1,
    x94102888: 1,
    xbaca516d: 1,
    xf0c716c2: 1,
    x24c44169: 1,
    x4063c1fb: 1,
    x1ea0f987: 1,
    x2a2fa4f7: 1,
    x4ea02976: 1,
    x03573a54: 1,
    xdddbcaf1: 7,
    x7e5f88b4: 1,
    xc66c0629: 1,
    xe8857141: 1,
    xbfe18fd4: 1,
    xda488ee3: 1,
    xc4a6beaf: 1,
    x3a4b78bd: 1,
    xdf011ee8: 1,
    xea0e7536: 1,
    x203a612e: 1,
    x1194705f: 1,
    x9db298a6: 1,
    x93de4c21: 1,
    x09257bce: 1,
    x5dd7b3ca: 1,
    x9a46a307: 1,
    x1966e94b: 1,
    xfb9e8895: 1,
    xa2727067: 1,
    xb076d924: 1,
    x49a18cb0: 1,
    xe9a2c1a1: 1,
    x86fa7cab: 1,
    x5769c9db: 1,
    xe2720454: 3,
    xee6d34b6: 1,
    x97ba4a80: 1,
    x8c4ec20a: 1,
    x978d733e: 1,
    x6e7b59ff: 1,
    xd2e08a23: 1,
    xf272a1be: 1,
    xc28f8a1e: 1,
    xad086f6f: 1,
    x6fb35d46: 1,
    x146ad46b: 5,
    xe99eabbc: 1,
    xbcaad5e4: 1,
    xee81cd2f: 1,
    xb7302275: 1,
    xa5f0d12a: 1,
    xf95dad93: 1,
    xef5e600b: 1,
    xaf3bdcc8: 1,
    xa14e8829: 1,
    x05f1cb40: 1,
    x65add163: 1,
    xff9c1977: 1,
    xa4cdd210: 1,
    x56143b9b: 1,
    xe0187f34: 1,
    xa1fd9a96: 1,
    xc03859ab: 1,
    xed6aecba: 1,
    x8027ea76: 1,
    xb02614d8: 1,
    x835028b9: 1,
    xe00cc6da: 1,
    x87792ee4: 3,
    xf7f00df0: 1,
    x59189747: 1,
    x80580ad8: 1,
    xdfc000b9: 1,
    x19c5df6d: 1,
    x10271371: 1,
    xb57ca94b: 1,
    x7edd54a6: 1,
    x54151681: 1,
    x7d5c9f6e: 1,
    xaa941315: 5,
    xb32a7131: 1,
    x1c1d0633: 1,
    x9da131be: 1,
    x3da6e59d: 1,
    x1931ceb7: 1,
    x63243988: 1,
    xa53d8f63: 1,
    x45fc9904: 1,
    x1c38dec1: 1,
    xa0a0619d: 1,
    x2310ae8c: 1,
    xa51ceb84: 1,
    xa3304e87: 1,
    x7d3e283c: 1,
    x413fb00e: 1,
    x620ff53a: 1,
    xf2d21d28: 1,
    x7b77ec94: 1,
    xf19d0090: 1,
    x56b48bc9: 1,
    x59415406: 1,
    xa108c92e: 3,
    x5f367296: 1,
    x3d350136: 1,
    x18944414: 1,
    x3dfa879a: 1,
    xb77152f5: 1,
    xe2647b40: 1,
    x4c9460e1: 1,
    x5f80beab: 1,
    x7ee7350a: 1,
    x254b93ac: 1,
    x04f1c2fb: 1,
    xacc4c7ed: 1,
    x3d4f9e49: 1,
    xec51722f: 1,
    x8b932b0f: 1,
    x6e193e19: 1,
    x5b239dac: 1,
    x012b721f: 1,
    x81f4957f: 7,
    xe3826c05: 1,
    x86d5acb6: 1,
    x3c150015: 1,
    xca6b047a: 1,
    x7309ea42: 1,
    x81b01c72: 1,
    x86ad151a: 1,
    x2c6df212: 1,
    x6f14c284: 5,
    xe3c73b1d: 1,
    x6562c839: 1,
    xd019317c: 1,
    xa91d63b4: 1,
    xaa49bb8e: 1,
    xd356edfd: 1,
    x5403e655: 1,
    x4ac7620a: 1,
    xe7960a7f: 5,
    xb39fe464: 1,
    xdcd7068c: 3,
    xe60ff65c: 1,
    x37546a86: 1,
    x108f20d2: 1,
    x47e94e74: 1,
    xee7f7f8e: 1,
    x774d6e2f: 1,
    x7bfa4bcb: 1,
    x6d0a6b15: 1,
    x6f0acc0d: 1,
    xe88db781: 1,
    x87165d81: 1,
    x3ddf36dc: 1,
    xd8a0c9f1: 1,
    x6067c3c2: 1,
    xb1c3ac91: 1,
    xd3d66aa1: 1,
    x9083fa57: 1,
    xc160411a: 1,
    x8e78c770: 1,
    x6930245c: 1,
    x6c878c01: 1,
    x5c24e933: 1,
    xb0090aa4: 1,
    x73c6fbe8: 1,
    x5e66c330: 1,
    xc421b153: 1,
    x2a97d5ae: 1,
    xf016be4d: 1,
    x091c3ba9: 1,
    x549ff726: 1,
    xa2e454d0: 1,
    x25a0b696: 1,
    xbece15d1: 1,
    xb8c1aca6: 1,
    x82b7062e: 1,
    x39387f7b: 1,
    xd5dd93e4: 1,
    x88ba20f0: 1,
    xc1ea891d: 1,
    x562c3268: 1,
    xd53a8a62: 1,
    xf3709240: 1,
    xd5c6a6d1: 1,
    x841bce91: 1,
    xbbb52080: 1,
    xb941bf0f: 1,
    x6c21827e: 7,
    xa7751cda: 1,
    x5175512e: 1,
    x4d41b028: 1,
    x2f165f83: 1,
    x37bdef77: 1,
    xcf210882: 1,
    x17d3ac33: 1,
    x5a617e10: 1,
    xf14f23a1: 1,
    x2a6ba0e1: 1,
    x38fe40cc: 1,
    x61f63fce: 1,
    x53c8509f: 1,
    x35a74633: 1,
    x9821b2d2: 1,
    xdeb326ed: 1,
    x69afa7d4: 1,
    x7f4b213f: 1,
    x247e77c1: 1,
    x3e7a00a1: 1,
    xc9fc3077: 1,
    x63712156: 1,
    x96d7d251: 1,
    x91485c5b: 1,
    xca44cb3e: 1,
    x2bbf01b4: 7,
    xc42214d2: 1,
    xa2a222e4: 1,
    x0ca28069: 1,
    x501659d5: 1,
    x35df89b1: 1,
    x4e225acb: 1,
    x28b1520d: 1,
    x9ee3ed79: 1,
    x537d3a36: 5,
    x420a1216: 1,
    x78751993: 1,
    x8671bf87: 1,
    xce3b43d9: 1,
    x743788b7: 1,
    x84e22c0c: 1,
    xbe09f8e0: 1,
    xb8aaa5b2: 1,
    x3c63bf7e: 1,
    xa9f46856: 1,
    xd5fb639e: 1,
    xf823b1e4: 1,
    xfd541b55: 1,
    x2e3a49c6: 1,
    x5af91a1a: 1,
    x3b520170: 1,
    xbe8d1586: 1,
    xdc9fecd7: 1,
    xc6e3f466: 1,
    x700887e9: 1,
    xb1e55c9b: 1,
    x8d1aaba0: 1,
    x0717dd73: 1,
    x86350467: 1,
    x804a28d7: 5,
    x687b7346: 1,
    x9fb497bc: 1,
    x863fa56f: 1,
    x07698bfa: 1,
    x4ba99c68: 1,
    xae9d04c6: 1,
    xea5d40f6: 1,
    xa5c1db94: 1,
    x99ebd235: 1,
    x444c9aa1: 1,
    xf7a39790: 1,
    x6e878ae6: 1,
    x4c689674: 1,
    xc3debe62: 1,
    xfc004793: 1,
    xe4f0d5a5: 1,
    x9dd868fc: 1,
    x06163acc: 1,
    x1dd3e583: 1,
    x8e7a3845: 1,
    xa78408f0: 1,
    x78a46623: 1,
    xa563e9bf: 1,
    xe5c1440f: 1,
    x60cf7e9b: 1,
    xc1b99215: 1,
    xa454969d: 1,
    x1447f141: 1,
    xeee360c1: 1,
    x5178bc0e: 1,
    x47e2fe7b: 1,
    x5591bdd6: 1,
    x92851a84: 1,
    x988100a0: 5,
    xc93408a0: 1,
    xed05e292: 1,
    xbe5a4907: 1,
    x2deda608: 1,
    x12104a53: 1,
    x17842ac9: 1,
    x93f464a6: 1,
    x9436863a: 1,
    x20397d84: 1,
    x6da0ad52: 3,
    x400ec2a1: 1,
    x6353bf20: 1,
    x8197076c: 1,
    x4c3db789: 1,
    x7c87a99d: 1,
    x5001a2ef: 1,
    xf2d086d9: 3,
    x09b06e04: 1,
    x2dd5f645: 1,
    xeabd9ece: 1,
    x0813b13f: 1,
    xb0761726: 1,
    x7286f3ed: 1,
    xa7792da2: 1,
    x7eedaeca: 1,
    xe43f31e7: 1,
    x61224d00: 1,
    x77b3abc0: 1,
    xe2eccfa0: 1,
    xb2268f78: 1,
    x80e6a2da: 1,
    x5a1fdcca: 5,
    x3d5e27ac: 1,
    xc97373d6: 1,
    x8d01f363: 1,
    x3eadbf2c: 1,
    x1648512e: 1,
    x6112501d: 1,
    xa376484d: 1,
    x76f40cf3: 1,
    xc4f30499: 1,
    x64273538: 1,
    x947a0f67: 1,
    x5c732f5b: 1,
    x6c2451c0: 1,
    xe459fe3b: 1,
    x28116fcc: 1,
    x0ccd75d5: 1,
    xbabbeff9: 1,
    xb76a4c29: 1,
    x7906ea89: 1,
    x311ca012: 1,
    xb5dad7df: 1,
    x28550f4e: 1,
    x7e2dbd2d: 1,
    xf5ea5e4a: 1,
    x3d618422: 1,
    xd3dd6afd: 1,
    x5e51a74e: 1,
    x662c012c: 1,
    x140e3a84: 1,
    x79f44d15: 1,
    x396bc8f0: 1,
    x0d5ff9f9: 1,
    x86eb9be5: 1,
    x12d1d6a4: 1,
    x1943e4c6: 1,
    xa63fbf72: 1,
    xc652a995: 1,
    x465fb369: 1,
    x3b07fdb5: 1,
    x53ed0760: 1,
    xf7ae060b: 1,
    x0f905733: 3,
    x65f544b9: 1,
    xf40ebc6f: 1,
    x7889975d: 1,
    x62ed76b5: 1,
    xf628fd0a: 1,
    x55698552: 1,
    xdecd7b24: 1,
    x0e143ada: 1,
    xf3101bbc: 1,
    x64300871: 1,
    xde97e79a: 1,
    x8edfe9ca: 1,
    x8f052cdc: 3,
    xf4478ed2: 1,
    xa0990d5f: 1,
    xaa2a5044: 1,
    x308395e0: 1,
    xe97018cb: 1,
    x74320891: 1,
    xf333e250: 1,
    x2ec1aad7: 1,
    xeb0769ac: 1,
    xf5e818c6: 1,
    x9ec66ebc: 1,
    x47826405: 1,
    x9c7f36e9: 1,
    x2a4cfc98: 1,
    x8ee87cd9: 1,
    x7002f520: 1,
    x47b77069: 1,
    x199fe701: 1,
    x84de0c31: 1,
    x462233f9: 1,
    xce647938: 1,
    xe57b857f: 1,
    xce0342b0: 1,
    xfbf2bb75: 1,
    xba201757: 1,
    xd6ba8945: 1,
    x0469494d: 1,
    x2484e8dd: 1,
    xea85ebd5: 1,
    xb04e8935: 1,
    xc76fcfca: 5,
    xf1194409: 1,
    x71a71e9a: 1,
    x9c808a81: 1,
    xf3e396ac: 1,
    x16668c75: 1,
    x2984ff46: 7,
    x7fa12c31: 1,
    xb28eba10: 5,
    x62442949: 1,
    xfe7ebea5: 1,
    xe60d9eb3: 1,
    x1bd6d6f5: 1,
    x4c9eb133: 1,
    x28f89c66: 1,
    xd4cf0c43: 1,
    x286c4f8e: 1,
    xc069ca91: 1,
    x68c33323: 1,
    x49131758: 1,
    x049eb835: 1,
    x8dbf1246: 1,
    x8bc57e8c: 1,
    xbd84dd87: 1,
    xb58b40b0: 1,
    xa166cac8: 1,
    xaa12e8bc: 1,
    x942b1108: 1,
    x81ca9426: 1,
    x90080f05: 1,
    x4bff4059: 1,
    x22292b07: 1,
    xc78b17a8: 1,
    x3109ff59: 1,
    xd3a07563: 5,
    x7fa4b02c: 1,
    xcc7bf363: 1,
    x7edeab25: 1,
    x8a802858: 1,
    x3a156830: 1,
    xdafd4c38: 3,
    x9b9b22e9: 1,
    x9cbf5636: 1,
    x78fde51a: 1,
    xad6d6cc5: 1,
    xb2e44095: 1,
    xc58d0781: 1,
    x628a332f: 1,
    x48edc8bd: 3,
    xebc64d53: 1,
    xa564f4fc: 1,
    xeb4024dc: 1,
    x7f558f47: 1,
    x4a526867: 1,
    x9b09b922: 1,
    x17e282ac: 1,
    xab99652a: 1,
    x44ae9c22: 1,
    x16717400: 1,
    x9beb8775: 1,
    xb2c42542: 1,
    x4c476731: 1,
    xdbde6261: 1,
    x9d101ff1: 1,
    x255e218a: 1,
    xc416ae59: 1,
    xa75a2a41: 1,
    xea54de9c: 1,
    x6284d5e4: 1,
    xc936e3c5: 1,
    x6bf85ee1: 1,
    x62bcf142: 1,
    x2aeb31da: 1,
    x6d4349b1: 1,
    xcfdd39a1: 1,
    x0dcc0d00: 1,
    x4ac8cc3c: 1,
    xadaffa75: 1,
    x9ec44c30: 1,
    xcd153c71: 1,
    xbb4d2092: 1,
    xa024ce40: 1,
    x1c962a28: 1,
    xa1684d18: 1,
    xcb89d2ff: 1,
    xd8ce692d: 1,
    x1a140f5d: 1,
    x8560c88c: 1,
    x3835fe92: 1,
    xb28742a4: 1,
    x7021d2b3: 1,
    x3a7cb457: 1,
    xf4e2e6c3: 1,
    xf860a95c: 1,
    x84d7ff37: 1,
    x044fb1bd: 1,
    x1d071836: 1,
    x05ce2cd7: 1,
    x3358c387: 1,
    x910c9c6c: 1,
    x6991fae8: 5,
    x7ee1ef96: 1,
    xadc5eced: 1,
    xb71566f5: 1,
    x1a3a89d4: 1,
    xf59a06cc: 7,
    xc922d880: 1,
    x8cd5c2d3: 1,
    x3621a503: 1,
    x1f4f5dce: 1,
    xe57b55c3: 1,
    xbf91d586: 1,
    xb825c855: 1,
    x9a19fda2: 1,
    x7b10eed3: 1,
    x7bc5cbd2: 1,
    xbc13e786: 1,
    x55f4c7ba: 1,
    x5d989eef: 1,
    x54a555e1: 1,
    xcbd47f15: 1,
    x8612e354: 1,
    x4ca78374: 1,
    x16d95883: 1,
    x66ea0adb: 1,
    xc9949c2c: 1,
    xbb3e7e03: 1,
    xf2b77352: 1,
    xbcf418c8: 3,
    xf32cd40d: 1,
    xf2f04a1c: 1,
    xf21277d8: 1,
    x49708777: 1,
    xd9b49cdb: 1,
    x92a6ed1f: 1,
    x53efe5d6: 1,
    xdc0ab7c3: 1,
    x15097f15: 1,
    xffca0c76: 1,
    x0928da8d: 1,
    x19ae5275: 1,
    x43fd501e: 1,
    x76b292f5: 1,
    xbec24fe3: 1,
    x2d917e50: 1,
    x97ecacc9: 1,
    x2a81c4d0: 1,
    x54d554bb: 1,
    xba0b4ddf: 1,
    x12353430: 1,
    xb5e1b1a9: 3,
    x1338dc73: 1,
    x72ea43c0: 1,
    xaf34418d: 1,
    x8647066a: 1,
    xaf3d410e: 1,
    x2de3e050: 1,
    x3f9e623c: 5,
    x9774e7f4: 1,
    x83de4d4b: 1,
    xfce4d1db: 1,
    x30f3fa67: 1,
    x62aa7abe: 1,
    x6dbda8f7: 1,
    x8ff047fc: 1,
    x23988c70: 1,
    x7f234822: 1,
    xbdd01871: 1,
    x6f72b75a: 1,
    xf8c3c666: 1,
    x176ca9c5: 1,
    xc7661d0c: 1,
    x4b141a7b: 1,
    x94b17269: 1,
    x94b87f90: 1,
    xd1f4b10c: 1,
    xc60ab5a6: 1,
    x3c6eadd4: 1,
    xa3230120: 1,
    x3e08256b: 1,
    xee385756: 1,
    x7c9c25f4: 1,
    x84cb1c4f: 1,
    xfc6a5117: 3,
    x11d36a4a: 1,
    x332d3283: 1,
    x7061af49: 1,
    xee0e6dfd: 1,
    xa6c5fe4a: 1,
    xb0f995e7: 1,
    xbe7abe50: 1,
    xa2a01f3b: 1,
    xff638cdc: 1,
    xd8166039: 1,
    x628c6ce1: 1,
    x570008fe: 1,
    x47d384b7: 1,
    x963908a2: 1,
    xd0202317: 1,
    x8919cac4: 1,
    x6b98eee6: 1,
    xdffb849e: 1,
    xab2612c9: 1,
    xad27f888: 1,
    x88ad8850: 1,
    x4cea4da4: 1,
    x394328ff: 1,
    x3b9cbe71: 1,
    x5f202214: 1,
    xce59c405: 1,
    x30f73346: 1,
    x0a5323fb: 1,
    xe090e035: 1,
    x3674256f: 1,
    x7797ba60: 1,
    x9fe00f40: 1,
    xcd790556: 1,
    x7ca58e56: 1,
    xe6bf74fb: 3,
    xa4d2b9e0: 1,
    x39f945bc: 1,
    xa58f637c: 1,
    x654473c2: 1,
    x9ce793ac: 1,
    x5f1b1d43: 1,
    xe91d8709: 1,
    x4dd4b3ce: 1,
    x0bfe476c: 1,
    x4ae9a165: 1,
    xc3727b1e: 1,
    xc75f8a9d: 1,
    xfd39b88b: 1,
    xb395b09e: 1,
    xe2c28508: 1,
    x1809a724: 1,
    x5c58addc: 1,
    x1b0d16d9: 1,
    x6c1b79ab: 1,
    x5c54c8fe: 1,
    x653473a7: 1,
    x873de41e: 1,
    xdf7a2b10: 1,
    xe9b645d1: 1,
    x53cb4fa4: 5,
    x6684ed05: 1,
    x34b66d0f: 1,
    x58888f0c: 1,
    x6a2c8fe8: 1,
    x10f73211: 1,
    x939e5317: 1,
    xea3f3b10: 1,
    xa91a8b2b: 1,
    x38d298db: 1,
    x0417645c: 1,
    xc2ed4dc1: 1,
    x979874fb: 1,
    xa22333dd: 1,
    x73e22d1a: 1,
    xfe7225c0: 1,
    x0bdd390b: 1,
    x1bc62cc1: 1,
    x3c783c21: 1,
    x5ded9120: 1,
    x1cd20de3: 1,
    x41c98dd9: 1,
    x72d71d72: 1,
    xa628d0b3: 1,
    x232c98c3: 1,
    x3eae4a76: 1,
    x3b357d4b: 1,
    xb8cada15: 1,
    x9f8fd83a: 1,
    xcbe64d9f: 1,
    x34dd08d4: 1,
    xc56dd17d: 1,
    x50adacca: 1,
    x1173d665: 1,
    x2687050a: 1,
    xdd21f766: 5,
    x0400c734: 1,
    xfdcb05c6: 1,
    x5c5ce8a7: 1,
    xdb555d82: 1,
    x49c77d91: 1,
    x055cc85e: 1,
    x5072f684: 1,
    x15118e31: 1,
    x1e67d721: 1,
    xdd097d26: 1,
    x97a16985: 1,
    x54cabcc1: 1,
    x29b62e57: 1,
    xa3f01930: 1,
    x0eb17cb3: 1,
    xb2ee4d8a: 1,
    xb2123fe7: 1,
    xb893eea0: 1,
    x894e9161: 1,
    xb2fb087f: 5,
    x530e001a: 3,
    xc6bd476d: 1,
    xf988e357: 1,
    x058a43ea: 1,
    x46dc515e: 1,
    xe65a8a52: 1,
    xb6a04e42: 1,
    xe5941bdb: 1,
    xa48fc3d9: 1,
    xe734027c: 1,
    x184dd6bd: 1,
    xd0cb6a64: 1,
    xb63f40f1: 1,
    x93d04437: 1,
    x090498b7: 1,
    x562537a3: 1,
    xb133e033: 1,
    x52acf144: 1,
    xbecbe02a: 1,
    xa20d4ff3: 1,
    x83e0ef34: 1,
    xc02612c1: 1,
    x0c97bcc6: 1,
    x6c4db3eb: 1,
    x0e6c2832: 1,
    xbff4ded8: 1,
    x87e1af64: 1,
    x24e8c54b: 1,
    x6f02821a: 1,
    xd8195401: 1,
    x352fe513: 1,
    x624f43fa: 1,
    x6b71d829: 1,
    x98e17791: 1,
    xb53995b9: 1,
    xf65c1b76: 7,
    x07501c73: 1,
    x973fa5da: 1,
    x161637fe: 1,
    x704abadf: 1,
    x4888e951: 1,
    xa5b48e65: 1,
    x130cfe00: 1,
    x7f151611: 1,
    xa1ef0297: 1,
    xdd628192: 1,
    xacf558ef: 1,
    x5f89a9ec: 3,
    xd320e7cb: 1,
    x882d6d35: 1,
    x04304287: 1,
    x52eea13e: 1,
    x884f3ff0: 1,
    xbdc01b47: 1,
    xc05c6bf8: 1,
    x53ec3ac2: 1,
    x14b53e7d: 1,
    x017ffe75: 1,
    x0171b5cf: 1,
    x611a8b9a: 1,
    xc3c10ce9: 1,
    x511ebee0: 1,
    xa5f76a10: 1,
    x1b9c70f9: 1,
    x521c318d: 1,
    xb8a8306d: 1,
    x0c817e77: 1,
    xd700dc37: 1,
    xfe8f8550: 1,
    x8b2b8542: 1,
    x44b0cf46: 1,
    x72c7e432: 5,
    x6be4aa76: 1,
    xdbdb9e2d: 1,
    x177dc76c: 3,
    xbd7989d4: 1,
    x91a81ae1: 1,
    xdfed110f: 1,
    xdde050d4: 1,
    xae392b81: 1,
    x82b1ced5: 1,
    x7a07e830: 1,
    x40bb658a: 1,
    x3040dbf0: 1,
    x03ab4adb: 1,
    xa1fcb373: 1,
    xf8e48356: 1,
    xe1ff3a67: 1,
    xd65b707a: 1,
    xc34aaa36: 1,
    xc251d1df: 1,
    x8db847be: 1,
    x4fd755cf: 1,
    xbe97067d: 1,
    x563a1373: 1,
    x08877f9f: 1,
    x639c5c88: 1,
    x88249f3d: 1,
    xa4f33d10: 1,
    x62373e8d: 1,
    x67587a41: 1,
    xfca4fb80: 1,
    x0781f49f: 1,
    x313ae17a: 1,
    x21a9b23e: 1,
    x16180e66: 1,
    x78d94585: 1,
    xad2801b0: 1,
    x539e9465: 1,
    x2ce71cd4: 1,
    x59415a2a: 1,
    xe3a84cfd: 1,
    x1514ad67: 1,
    x370acca0: 1,
    xbf0e54a1: 1,
    x3ec5a002: 1,
    x4f7e60c4: 1,
    x4885402c: 1,
    x732200a5: 1,
    xa8021b18: 1,
    x382dd97c: 1,
    x93f0d4cf: 1,
    xd173bd8b: 1,
    x9a83948a: 1,
    xfdd306dd: 1,
    xc132566e: 1,
    x08200a09: 3,
    x46775f5c: 5,
    xe9f9c51c: 1,
    xf2be1955: 1,
    xd281abcc: 1,
    xf2962025: 1,
    x71779377: 1,
    xf13cac05: 1,
    x75f23cda: 1,
    xc97e18db: 1,
    x77292d12: 1,
    x695be439: 1,
    x46422a1c: 1,
    x477b7d15: 1,
    xd156ed86: 1,
    xea66b9f0: 1,
    xbda68f33: 1,
    x158fc9de: 1,
    xbab48ae7: 1,
    xd9c4f031: 1,
    x7337b987: 1,
    xcea03217: 1,
    x8f535fe1: 1,
    x69b4a17e: 1,
    xc4f32a85: 1,
    x95ebd594: 1,
    xec2fe868: 1,
    xc1f65818: 1,
    x6c2c2e3d: 1,
    x8fda68cf: 1,
    x681b083a: 1,
    xa181a573: 1,
    x825accdf: 1,
    x8151a2ab: 1,
    x817743fd: 1,
    xab6af5af: 1,
    x8ab9fa36: 1,
    xc7ac962d: 1,
    xefb45b99: 1,
    x6e0810b1: 1,
    x1cb74123: 1,
    x3f7a0038: 2,
    x6c17a62f: 2,
    x2fa68c5c: 6,
    x99512cf4: 6,
    x531ef9ff: 2,
    x3841d731: 2,
    x77223aab: 2,
    xf86e37a6: 2,
    x5cfae8d7: 2,
    xaf8dc15b: 2,
    x85148312: 2,
    x09018e1a: 2,
    x78c82aed: 2,
    xe079f247: 2,
    x5a079232: 2,
    xa8acd413: 2,
    xe356eee2: 2,
    x6eb37886: 2,
    x52870e5e: 2,
    x71cf3675: 2,
    x8db5c516: 2,
    x67c339d3: 2,
    x736e348e: 2,
    x1eb085a4: 2,
    xad5b5561: 2,
    x5cc642b6: 2,
    x678a6157: 2,
    x42fade84: 2,
    xcf7adcd9: 2,
    xc98a936f: 2,
    x9027db20: 2,
    xd002d767: 6,
    xf182b5c9: 2,
    x54af4372: 2,
    x6b8ca2c3: 2,
    xc19485df: 2,
    x4291b19d: 2,
    x95f128d7: 2,
    x26d4ddea: 2,
    x31d0a11c: 2,
    xc46050cd: 2,
    x574ea201: 2,
    x7426e0ba: 2,
    x0554a9b2: 2,
    x7afa3270: 2,
    xbfda9411: 2,
    x34c5114d: 2,
    xc540ec34: 2,
    xb2507830: 2,
    x91cd0674: 2,
    xf023f423: 2,
    x9956821e: 2,
    x12e55b24: 2,
    xc6d82b0c: 2,
    x98d23ddc: 2,
    x084ef1fb: 6,
    xb103b2d2: 2,
    x1ad3b27f: 2,
    x30b3eff5: 2,
    xe8566140: 2,
    x15bbefdd: 2,
    x643bbce7: 2,
    x4c3965a8: 2,
    xc288e24e: 2,
    x9e633712: 2,
    xaeaf3a73: 2,
    x9699159d: 2,
    x36ca34b9: 2,
    xdd4aa9d9: 2,
    xfd7f2e1a: 2,
    xa646e955: 2,
    x7d065bcf: 2,
    x0956af08: 2,
    x3c05662b: 2,
    x78836e42: 2,
    x021c398d: 2,
    x5ac02d88: 2,
    x8bb84c27: 2,
    x1815b310: 2,
    x4f759082: 2,
    xa2de6c90: 2,
    xca19ff6f: 2,
    xab67de94: 2,
    x2bedadb3: 2,
    x2e35a038: 2,
    xe722119c: 2,
    xbe5f5039: 2,
    x1746d8eb: 2,
    xf7632f49: 2,
    x70efdb9a: 2,
    x3a5638d2: 2,
    x0b4a5445: 2,
    x4bdbb99f: 2,
    x8f230b86: 2,
    x354c80ff: 2,
    xd6c53b4f: 2,
    x9f7057be: 2,
    x6f2b387b: 2,
    xa55a9e08: 2,
    xb78db9fe: 2,
    x72554cd7: 2,
    x0c39af34: 2,
    xb856ebfd: 2,
    xc52805cf: 2,
    xf78d9493: 2,
    xd1d4fc02: 6,
    x4acbddd5: 2,
    xce197df4: 2,
    x4e3d8af0: 2,
    xffa3bda3: 2,
    xc5ccaa1c: 2,
    x08328a5c: 2,
    x36b9497a: 2,
    xa375d2cd: 2,
    xb5586526: 2,
    x4aeb94c8: 2,
    xa0e0d5b3: 2,
    xd7dfc68e: 2,
    xb36fa3c4: 2,
    x729555b0: 2,
    x5d4228cf: 2,
    x5a30dc03: 4,
    xa3826afb: 4,
    x3016b82c: 4,
    xef9fdf0f: 4,
    x00ef5a8c: 4,
    x257433e8: 4,
    xf890a9bf: 4,
    xeeba74b4: 4,
    x46f446cb: 4,
    xd11c0d49: 4,
    x70a05ff1: 4,
    x4048db62: 4,
    xb82e5e57: 4,
    xee208e3e: 4,
    xd60db106: 4,
    x91e639be: 4,
    xe21e87ea: 4,
    x17833efa: 4,
    xccd520c6: 4,
    x6f983698: 4,
    xf413c9b3: 4,
    x85ea9185: 4,
    x8e37c0f0: 4,
    x15cc1c46: 4,
    x552136ec: 4,
    x49210026: 4,
    x3fb8ea59: 4,
    xfa3b65a2: 4,
    x0b37cd39: 4,
    x24916211: 4,
    xb6924928: 4,
    xeb29583f: 4,
    x1d75d011: 4,
    x7436f47a: 4,
    x3dfce941: 4,
    x5acdfae5: 4,
    x918dc65f: 4,
    x9b0f5c45: 4,
    x422cdd7b: 4,
    x58a23880: 4,
    x31f1a536: 4,
    x4fe3f117: 4,
    x753bfb07: 4,
    x290f0b27: 4,
    x6c51f222: 4,
    x8471c25e: 4,
    xeb552f4f: 4,
    x5d2a39e8: 4,
    x9ae2d44c: 4,
    x81a62ada: 4,
    x63cb6ddf: 4,
    x39bf34c7: 4,
    xbeb8883c: 4,
    xfaf19f39: 4,
    x4d233bb6: 4,
    xab05415d: 4,
    x06d58dc9: 4,
    x83431c51: 4,
    xe36d4df1: 4,
    xf00f1f76: 4,
    x10d21d53: 4,
    xd5942577: 4,
    x150b84bc: 4,
    x98aeb3f4: 4,
    x9b708b3d: 4,
    xaa49eb23: 4,
    x2ddc9314: 4,
    x5fd649a1: 4,
    x72bf29ac: 4,
    xf3383ce9: 4,
    x2c975b71: 4,
    x76ac47e1: 4,
    xe48ec825: 4,
    x9c8910ce: 4,
    x38c557b0: 4,
    x4e55e960: 4,
    xe3a6c34c: 4,
    x8df47e1c: 4,
    xd9736689: 4,
    x65b888a9: 4,
    xc76f5175: 4,
    x0dd784dc: 4,
    x4d4ab879: 4,
    x10dd79dd: 4,
    x68fa72f1: 4,
    x78f8d426: 4,
    x260a303b: 4,
    x64d5fbc5: 4,
    x65ea0edb: 4,
    x0ffd06bf: 4,
    x23810bdd: 4,
    x6a689b6e: 4,
    x2792a26b: 4,
    xe8f3f175: 4,
    xba23abaa: 4,
    x10b40975: 4,
    xe7b61d28: 4,
    x627bd971: 4,
    x28b61531: 4,
    x427c1f0c: 4,
    x47e55a6a: 4,
    xc66395f7: 4,
    x5a576baf: 4,
    x5a2543bb: 4,
    xe0eb33f1: 4,
    x6f44b58e: 4,
    x87e684d5: 4,
    x0ee10597: 4,
    x3c270651: 4,
    x665651d9: 4,
    x8209c2b6: 4,
    x3b368116: 4,
    x1eaae7d9: 4,
    xaefa9363: 4,
    x66f77983: 4,
    x809e9db7: 4,
    x3903ed6d: 4,
    xb42daa2e: 4,
    x4132d965: 4,
    xaeff16aa: 4,
    xa91f14c8: 4,
    x82cf9147: 4,
    x30746fab: 4,
    x89d8f98a: 4,
    x83c4bdd6: 4,
    x8bbd6711: 4,
    xad1703e1: 4,
    xb309e7af: 4,
    xa18511c1: 4,
    x62bdf245: 4,
    x98bdfa41: 4,
    x97c55853: 4,
    x35cdc0f1: 4,
    x4c444809: 4,
    x6d365bde: 4,
    xf55a5dee: 4,
    x178987f6: 4,
    x54d2046f: 4,
    x91c219a6: 4,
    xd913bd6d: 4,
    x64c7acd4: 4,
    x7287f0ff: 4,
    x9142cc8a: 4,
    x9f8588dd: 4,
    x029560eb: 4,
    x089171b4: 4,
    xe6db56ea: 4,
    xf63b3ab1: 4,
    x04f7a711: 4,
    x7282a78b: 4,
    x131a50cd: 4,
    x126d537c: 4,
    x5ebaaa00: 4,
    xf533c777: 4,
    x7dc50eb8: 4,
    xa527e820: 4,
    x03ba49c5: 4,
    x571a487b: 4,
    x68706f00: 4,
    x087ca5ba: 4,
    xadb14b9c: 4,
    xe2bb6023: 4,
    xc8f6a8e2: 4,
    xd564782a: 4,
    xf72b9244: 4,
    x30a67971: 4,
    xcd15d6f1: 4,
    x5e160730: 4,
    x80019db1: 4,
    x5ebfd8ed: 4,
    xb8165bda: 4,
    x86e0735c: 4,
    xaa0a192f: 4,
    x7ba015a6: 4,
    x74d6a391: 4,
    x6134fcd6: 4,
  };

  const CHAIN = "0x38";

  const wallet = useMetaMask();
  const { connect, status, ethereum, chainId, account } = wallet;
  const [discordId] = useQueryParam("discordId", StringParam);
  const [signature, setSignature] = useState();

  const msgParams = JSON.stringify({
    domain: {
      chainId: chainId,
      name: "Darling Waifu",
      version: "1",
    },
    message: {
      message: `Hi, thanks for buying our founder pass. You can sign this message to get the founder role in our server. Your unique id is: ${discordId}`,
    },
    primaryType: "Body",
    types: {
      Body: [{ name: "message", type: "string" }],
    },
  });

  const sign = () => {
    if (ethereum && account && discordId) {
      ethereum
        .request({
          method: "eth_signTypedData_v4",
          params: [account, msgParams],
        })
        .then((result) => {
          console.log(result);
          setSignature(result);
        })
        .catch(console.error);
    }
  };
  useEffect(() => {
    const { status, ethereum, chainId } = wallet;

    if (ethereum && status === "connected" && chainId !== CHAIN) {
      ethereum.on("message", console.log);
      ethereum
        .request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: CHAIN,
            },
          ],
        })
        .catch((error) => {
          console.error(error);
          if (error.code === 4902)
            ethereum
              .request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: CHAIN,
                    blockExplorerUrls: ["https://bscscan.com"],
                    chainName: "Binance Smart Chain",
                    rpcUrls: ["https://bsc-dataseed.binance.org/"],
                    nativeCurrency: {
                      decimals: 18,
                      name: "BNB",
                      symbol: "BNB",
                    },
                  },
                ],
              })
              .then(console.log)
              .catch(console.error);
        });
    }
  }, [wallet]);
  return (
    <div className="w-full min-h-full h-screen table text-bordergray items-center font-baloo">
      <Helmet defer={false}>
        <html lang="en" amp />
        <title>Darling Waifu</title>
        <meta name="icon" href={favicon} />
        <meta name="description" content="Darling Waifu - Discord verification" />
        <meta name="theme-color" content="#F0C1DC" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:title" content="Darling Waifu" />
        <meta property="og:type" content="game" />
        <meta property="og:url" content="https://founders.darlingwaifu.com" />
        <meta property="og:image" content={preview} />
        <meta property="og:description" content="Darling Waifu - Discord Verification" />
      </Helmet>
      <div className="table-row-group">
        <div className="table-row">
          <Background background={background.img} />
          <ContractLinks />
          <Container>
            <Verify
              connect={connect}
              status={status}
              account={account}
              alreadyHas={wallets[account?.substring(1, 10) || ""]}
              sign={sign}
            />
            <Signature signature={signature} />
          </Container>
          <Social />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DiscordContained;
