import React from "react";
import { graphql } from "gatsby";
import "../assets/css/index.css";
import { MetaMaskProvider } from "metamask-react";
import DiscordContained from "../components/layouts/discordContained";

const Index = ({
  data: {
    site: { background },
  },
}) => {
  return (
    <MetaMaskProvider>
      <DiscordContained background={background} />
    </MetaMaskProvider>
  );
};

export default Index;

export const pageQuery = graphql`
  query {
    waifus: allContentfulWaifu {
      edges {
        node {
          id
          name
          body {
            img: gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
          }
          head {
            img: gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }
    }
    site: contentfulSite {
      background {
        img: gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;
