import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Signature = ({ signature }) => {
  return (
    <div className="w-full text-center flex flex-col items-center justify-center">
      {signature && (
        <div className="w-3/4 lg:w-96 flex flex-col justify justify-center text-center break-all">
          <div className="w-full text-center flex flex-col items-center justify-center">
            Use this code with the /verify command in Discord:
          </div>
          <div className="w-full text-center flex flex-col items-center justify-center">
            {signature}
          </div>
          <CopyToClipboard text={signature}>
            <div className="w-full flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 cursor-pointer"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z" />
                <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z" />
              </svg>
            </div>
          </CopyToClipboard>
        </div>
      )}
    </div>
  );
};

export default Signature;
